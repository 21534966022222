import React, {lazy} from 'react'
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom'
import AccessibleNavigationAnnouncer from './components/AccessibleNavigationAnnouncer'

const Layout = lazy(() => import('./containers/Layout'))
const Login = lazy(() => import('./pages/Login'))
export const BackendURL = "https://depoapi.nadev.site" // "http://localhost:8000"
// const CreateAccount = lazy(() => import('./pages/CreateAccount'))
// const ForgotPassword = lazy(() => import('./pages/ForgotPassword'))

function App() {
    return (
        <>
            <Router>
                <AccessibleNavigationAnnouncer/>
                <Switch>
                    <Route path="/login" component={Login}/>
                    <Route path="/app/*" component={Layout}/>

                    <Redirect exact from="/" to="/login"/>
                </Switch>
            </Router>
        </>
    )
}

export default App
